@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');
@import url('@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css');
@tailwind base;
@tailwind components;
@tailwind utilities;

body,
pre {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::placeholder {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --swiper-theme-color: #36497a !important;
}

.calculation-box {
  height: fit-content;
  width: fit-content;
  position: absolute;
  bottom: 40px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 15px;
  text-align: center;
  z-index: 11;
  border-radius: 10px;
}
.calculation-box p {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
}
.delete-area {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  cursor: pointer;
}

.calculation-box-line {
  height: fit-content;
  width: fit-content;
  position: absolute;
  bottom: 40px;
  left: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 15px;
  text-align: center;
  z-index: 11;
  border-radius: 10px;
}
.calculation-box-line p {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
}

.delete-line {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  cursor: pointer;
}

.hide {
  display: none;
}

.show {
  display: inline-block;
}
/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

input,
textarea,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  cursor: default;
}

a {
  text-decoration: none;
  cursor: pointer;
}
a p {
  cursor: pointer;
}

a div {
  cursor: grab;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select::-ms-expand {
  display: none;
}

.swiper {
  height: 11rem;
}

.mainSwiper {
  height: fit-content;
}

.bg-white {
  background-color: white;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: gray;
  border: 1px solid #aaa;
}
::-webkit-scrollbar-thumb:active {
  background: linear-gradient(to left, #22add4, #1e98ba);
}

.map {
  position: relative;
  top: 0;
  /* left: -0.4rem; */
  /* width: 100%; */
  box-sizing: border-box;
  border-radius: 12px;
}

.map-container {
  box-sizing: border-box;
  height: 630px;
  /* width: 100%; */
  padding: 0 0 0 1rem;
}

@media (max-width: 930px) {
  .map-container {
    height: 400px;
  }
}
@media (max-width: 930px) {
  .map-container {
    height: 320px;
  }
}
.map-container-admin {
  height: 25rem;
  width: 39rem;
  border-radius: 12px;
}

.print {
  position: absolute;
  z-index: 100;
  right: 0.6rem;
  top: 15rem;
  background-color: white;
  padding: 0.13rem;
  border-radius: 4px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}
.layers {
  position: absolute;
  z-index: 100;
  right: 0.6rem;
  top: 17.2rem;
  background-color: white;
  padding: 0.13rem;
  border-radius: 4px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.cursor {
  cursor: pointer;
}

.cursor[type='radio'] {
  transform: scale(1.3);
}

.icon {
  align-self: flex-end;
}

.layers-list {
  display: flex;
  flex-direction: column;
}

.map-container > input {
  cursor: pointer;
}

.layers-list-flex {
  display: flex;
  margin: 0.3rem 0.5rem;
  align-items: center;
}

.coordinates {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  position: relative;
  bottom: 5rem;
  left: 2rem;
  padding: 5px 10px;
  margin: 0;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  width: 15rem;
}

canvas {
  border-radius: 1rem;
}
.MuiAccordionDetails-root {
  background: #f4f4f4;
}

.MuiTypography-root {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600 !important;
}

.document-div {
  position: sticky !important;
  top: 1rem !important;
}

@media print {
  .document-div {
    position: relative !important;
    top: 0rem !important;
  }
}
.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 15px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  width: 36.2rem;
  height: 5rem;
}

.react-multi-select > div {
  border: 2px solid black;
  border-radius: 10px;
  font-size: 1.1rem;
  margin: 0 0 1rem 0;
}
.react-multi-select > span {
  border: none;
  border-radius: none;
}

.video-container-styles {
  width: 60%;
  margin: 0 auto;
}

.playlist-queue-styles {
  box-shadow: 0px 0px 1px white;
  display: flex;
  align-items: center;
  overflow-x: auto;
  background-color: #212836;
}

.playlist-queue-item-styles {
  margin: 5px;
  cursor: pointer;
  min-width: 60px;
  max-width: 60px;
  min-height: 60px;
  max-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid transparent;
}

.current-playing-video-styles {
  box-shadow: 0px 0px 2px white;
  transition: 0.2s;
}

.thumbnail-styles {
  width: 100%;
  height: 100%;
}

.video-styles {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 549px) {
  .video-container-styles {
    width: 100%;
  }
}
.MuiPaper-root {
  margin: 1rem 0;
  box-shadow: 0px 0px 0.2rem 0.2rem rgba(0, 0, 0, 0.5) !important;
}

.table-cell {
  cursor: pointer;
}

.MuiTableCell-root {
  font-size: 1.1rem !important;
}

@media (max-width: 930px) {
  :root {
    --swiper-navigation-size: 25px !important;
  }
}

.mapboxgl-popup-content {
  width: 20rem;
  height: 10rem;
  pointer-events: auto;
  overflow-y: scroll;
  position: relative;
  padding: 1rem 2rem 1rem 1rem;
}

.mapboxgl-popup-close-button {
  position: fixed;
  top: 1rem;
  right: -6.5rem;
  background-color: #ff635c;
  border-radius: 50px;
}

.mapboxgl-marker {
  background: no-repeat url('./assets/podcast.png');
  height: 32px;
  width: 32px;
  cursor: pointer;
}

.mapboxgl-marker svg {
  height: 0px;
  width: 0px;
}

.create-project {
  background: no-repeat url('./assets/pointer.png') !important;
  height: 32px;
  width: 32px;
  cursor: pointer;
}
.create-project svg {
  height: 32px;
  width: 32px;
}
